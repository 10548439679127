import uniqId from 'uniqid'
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import NumberFormat from 'react-number-format';
import { getAppraisalCycle, getCompetenciesList, getSkillsList, gradeList, getWeightage } from '../action/Master';
import { getGoal } from '../action/employee/Goal';
import { validateForm, onChange, onArrayChange, isEmpty, getCookie, setOptions, setError } from '../utils';
import ModalWindow from '../component/common/ModalWindow';
import CONSTANT from '../constant';
import constant from '../constant';

class Add extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            updated: false,
            self: false,
            type: 'behaviour',
            constrant: undefined,
            yearCycle: { value: '', name: 'yearCycle', options: [], error: '', isRequired: true },
            monthCycle: { value: '', name: 'monthCycle', options: [], error: '', isRequired: true },
            comments: { value: '', name: 'comments', error: '', isRequired: true },
            behaviour: { value: [], name: 'behaviour', error: '', isRequired: true },
            department: { value: [], name: 'department', error: '', isRequired: true },
            activity: { value: [], name: 'activity', error: '', isRequired: true },
            approved: { value: false, name: 'approved', error: '', isRequired: true },

            name: { value: [], name: 'name', error: '', boptions: [], doptions: [], aoptions: [], isRequired: true },
            weightage: { value: '', name: 'weightage', error: '', isRequired: true },
            pfaByEmp: { value: [], name: 'pfaByEmp', options: [], error: '', isRequired: true },
            remarks: { value: '', name: 'remarks', error: '', isRequired: true },
            check: { value: false, name: 'check' },
            updateedCode: undefined,
             sub: true
          }

    }

    componentDidMount() {
        
         
        const { designation, department, } = JSON.parse(window.atob(getCookie('token').split('.')[1]));
          
         this.loadList(designation, department)
    }

    public render() {

        const { updated, showModal, check, pfaByEmp, type, yearCycle, monthCycle, comments, behaviour, department, activity, name, remarks, weightage } = this.state;

        return (
            <React.Fragment>
                 
                <section>
                    <div className="col-lg-12 main-container">
                        <div className="fluid-container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="col-lg-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-6 pl-0">
                                                <h5 className="heading-h1">Add Goal</h5>
                                            </div>
                                        </div>
                                    </div>
                                     
                                      

                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>Appraisal
                                            </b>
                                        </div>
                                        <div className="card-body">
                                           
                                             {
                                                <form>
                                                    <div className="col-lg-12 mb-2">
                                                        <div className="row">
                                                            <div className="col-lg-6 form-group">
                                                                <label > Appraisal Cycle  </label>
                                                                {this.renderSelect(yearCycle, false)}
                                                            </div>
                                                            <div className="col-lg-6 form-group">
                                                                <label >Sub Performance </label>
                                                                {this.renderSelect(monthCycle, true)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            }
                                             

                                              
                                        </div>
                                    </div>
                                     
                                    <div className="card mt-2">
                                        <div className="card-header">
                                            <b>  Appraisal / Review
                                            </b>
                                        </div>
                                         

                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}> Behavioural</th>
                                                            <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}>Weightage </th>
                                                            {this.props.code && <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}>Percentage Of Achievement By Employee </th>}
                                                            <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}>Remarks</th>
                                                            <th scope="col" className="md-width text-right"> {
                                                                // behaviour.value.length < 2 &&
                                                                <i onClick={() => { this.toggleWindow(behaviour.name) }} className="fa fa-plus add-plus"></i>}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                         


                                                        {behaviour.error.length > 0 && behaviour.value.length < (this.state.constrant ? Math.floor(100 / Number(this.state.constrant.maxBehaviour)) : 1) && <tr>
                                                            <td className="text-center" colSpan={7}><span className="text-danger"> Please selecet minimum {this.state.constrant ? Math.floor(100 / Number(this.state.constrant.maxBehaviour)) : 0} behaviour</span> </td>
                                                        </tr>}

                                                         
                                                        {
                                                            behaviour.value.length > 0 && behaviour.value.map((item: any, ind: any) => {
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td> {item.weightage} </td>
                                                                        {this.props.code && <td> {item.pfaByEmp.length && item.pfaByEmp[0].percentage} </td>}
                                                                        <td> {item.remarks} </td>
                                                                        <th>
                                                                            <i className="fa fa-pencil add-plus" onClick={() => this.onEdit(ind, item, behaviour.name)}></i> &nbsp;&nbsp;&nbsp;
                                                                            {!this.props.code && <i className="fa fa-close add-plus" onClick={() => this.onDelete(ind, behaviour.name)}></i>}
                                                                        </th>

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>




                                        
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}> Directorate Specific Skills</th>
                                                            <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}>Weightage </th>
                                                            {this.props.code && <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}>Percentage Of Achievement By Employee </th>}
                                                            <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}>Remarks</th>
                                                            <th scope="col" className="md-width text-right">{
                                                                // department.value.length < 2 &&d
                                                                <i onClick={() => { this.toggleWindow(department.name) }} className="fa fa-plus add-plus"></i>}</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                         

                                                        {department.error.length > 0 && department.value.length < (this.state.constrant ? Math.floor(100 / Number(this.state.constrant.maxDepartment)) : 1) && <tr>
                                                            <td className="text-center" colSpan={7}><span className="text-danger"> Please selecet minimum {this.state.constrant ? Math.floor(100 / Number(this.state.constrant.maxDepartment)) : 0} department</span> </td>
                                                        </tr>}
                                                         
                                                        {
                                                            department.value.length > 0 && department.value.map((item: any, ind: any) => {
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td> {item.weightage} </td>
                                                                        {this.props.code && <td> {item.pfaByEmp.length && item.pfaByEmp[0].percentage} </td>}
                                                                        <td> {item.remarks} </td>
                                                                        <th>
                                                                            <i className="fa fa-pencil add-plus" onClick={() => this.onEdit(ind, item, department.name)}></i>&nbsp;&nbsp;&nbsp;
                                                                            {!this.props.code && <i className="fa fa-close add-plus" onClick={() => this.onDelete(ind, department.name)}></i>}
                                                                        </th>

                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}>Activities</th>
                                                            <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}>Weightage </th>
                                                            {this.props.code && <th scope="col" className={this.props.code ? "md-width" : "md-widthh"} >Check if Worked </th>}
                                                            {this.props.code && <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}>Percentage Of Achievement By Employee </th>}
                                                            <th scope="col" className={this.props.code ? "md-width" : "md-widthh"}>Remarks</th>
                                                            <th scope="col" className="md-width text-right"><i onClick={() => { this.toggleWindow(activity.name) }} className="fa fa-plus add-plus"></i></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {activity.error.length > 0 && activity.value.length < (this.state.constrant ? Math.floor(100 / Number(this.state.constrant.maxActivities)) : 1) && <tr>
                                                            <td className="text-center" colSpan={7}><span className="text-danger"> Please selecet minimum {this.state.constrant ? Math.floor(100 / Number(this.state.constrant.maxActivities)) : 0} activity</span> </td>
                                                        </tr>}
                                                        {
                                                            activity.value.length > 0 && activity.value.map((item: any, ind: any) => {
                                                                return (
                                                                    <tr key={ind}>
                                                                        <td>{item.name}</td>
                                                                        <td> {item.weightage} </td>
                                                                        {this.props.code && <td> {item.check ? 'Yes' : 'No'} </td>}
                                                                        {this.props.code && <td> {item.pfaByEmp.length && item.pfaByEmp[0].percentage} </td>}
                                                                        <td>{item.remarks}</td>
                                                                        <th>
                                                                            <i className="fa fa-pencil add-plus" onClick={() => this.onEdit(ind, item, activity.name)}></i>&nbsp;&nbsp;&nbsp;
                                                                            {!this.props.code && <i className="fa fa-close add-plus" onClick={() => this.onDelete(ind, activity.name)}></i>}
                                                                        </th>
                                                                    </tr>)
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="card-header">
                                            <b>Comments </b>
                                        </div> */}
                                    {/* <div className="card-body">
                                            <form>
                                                <div className="col-lg-12 mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-12 form-group">
                                                            <textarea
                                                                name={comments.name}
                                                                onChange={this._onChange}
                                                                value={comments.value}
                                                                className={comments.error.length > 0 ? 'form-control is-invalid' : 'form-control'}
                                                                placeholder="Enter comment" >
                                                            </textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div> */}

                                </div>
                                <div className="col-lg-12 mt-3 mb-3">
                                    <button onClick={this.onSubmit} className="col-lg-2 btn button-full mt-1 ml-1 pull-right">Submit </button>
                                    <Link to={CONSTANT.url.empSetGoal} className="col-lg-2 btn btn-secondary mt-1 pull-right">Cancel </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                
                
                 {
                    showModal && (
                        <ModalWindow
                            title={department.name === type ? ' Directorate Specific Skills' : behaviour.name === type ? 'Behavioural' : 'Activities'}
                            backdrop="static"
                            toggleModal={() => { this.toggleWindow() }}
                            className="modal-lg" >
                            <div>
                                <div className="row">
                                    <div className="col-lg-12 mb-2">
                                        <div>
                                            <form>
                                                <div className="mb-2">
                                                    <div className="row">
                                                        <div className="col-lg-6 form-group">
                                                            <label> Name  </label>
                                                          
                                                            <Typeahead
                                                                id="ta-name_name"
                                                                multiple={false}
                                                                allowNew={type == activity.name ? true : false}
                                                                disabled={this.props.code ? ((type == activity.name && !updated) ? false : true) : false}
                                                                placeholder="Select name"
                                                                name={name.name}
                                                                minLength={0}
                                                                options={type == behaviour.name ? name.boptions : type == department.name ? name.doptions : name.aoptions}
                                                                onChange={(e: any) => this._typeaheadOnChange(name.name, e)}
                                                                selected={name.value}
                                                                isInvalid={name.error.length > 0}
                                                                labelKey="name"
                                                            />


                                                        </div>
                                                        <div className="col-lg-6 form-group">
                                                            <label>Weightage </label>
                                                            <NumberFormat
                                                                disabled={(this.props.code && updated ? true : false)}
                                                                name={weightage.name}
                                                                onChange={this._onChange}
                                                                value={weightage.value}
                                                                type="text"
                                                                className={weightage.error.length ? 'form-control is-invalid' : 'form-control'}
                                                                placeholder="Enter weightage"
                                                            />
                                                            {type == activity.name &&
                                                                <small> Maximum Weightage will be {this.state.constrant ? this.state.constrant.maxActivities : <span className="text-danger">yet not set by admin</span>}</small>
                                                            }
                                                            {type == behaviour.name &&
                                                                <small> Maximum Weightage will be {this.state.constrant ? this.state.constrant.maxBehaviour : <span className="text-danger">yet not set by admin</span>}</small>
                                                            }
                                                            {type == department.name &&
                                                                <small> Maximum Weightage will be {this.state.constrant ? this.state.constrant.maxDepartment : <span className="text-danger">yet not set by admin</span>}</small>
                                                            }

                                                        </div>



                                                        {this.props.code && <div className="col-lg-6 form-group">
                                                            <label>Percentage Of Achievement By Employee </label>
                                                            <Typeahead
                                                                id="ta-name_name"
                                                                multiple={false}
                                                                placeholder="Select Percentage"
                                                                name={pfaByEmp.name}
                                                                minLength={0}
                                                                options={pfaByEmp.options}
                                                                onChange={(e: any) => this._typeaheadOnChange(pfaByEmp.name, e)}
                                                                selected={pfaByEmp.value}
                                                                isInvalid={pfaByEmp.error.length > 0}
                                                                labelKey="percentage"
                                                            />

                                                        </div>}
                                                        {this.props.code && <div className="col-lg-6 form-group">
                                                            <label>Remarks </label>
                                                            <textarea
                                                                name={remarks.name}
                                                                onChange={this._onChange}
                                                                value={remarks.value}
                                                                className={remarks.error.length ? 'form-control is-invalid' : 'form-control'}
                                                                placeholder="Enter remarks"
                                                            />
                                                        </div>}


                                                        {(this.props.code && type === activity.name) && <div className="col-lg-6 form-group">
                                                            <div className="form-check mt-3">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="gridCheck"
                                                                    name={check.name}
                                                                    checked={check.value}
                                                                    onChange={this._onChange}
                                                                />
                                                                <label className="form-check-label"> Check if Worked </label>
                                                            </div>
                                                        </div>}

                                                        <div className="col-lg-12 ">
                                                            <button onClick={updated ? this.onUpdate : this.onAdd} className="col-lg-2 btn button-full pull-right">Submit</button>
                                                            <button onClick={() => { this.toggleWindow() }} className="col-lg-2 btn btn-secondary mr-3 pull-right">Cancel</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ModalWindow>
                    )}
            </React.Fragment>

        )
    }

    private toggleWindow = (e?: any, updated?: any) => {
       
        const { showModal, } = this.state
        e && this.setState({ type: e, })
        this.setState({ showModal: !showModal, updated: updated ? true : false });
        
        // if (!this.props.code)
        //     this.setState({
        //         weightage: (e !== this.state.activity.name ? { ...this.state.weightage, value: 50 } : this.state.weightage)
        //     })

        
        if (showModal)
            this.setState({
                name: { ...this.state.name, value: '', error: '' },
                weightage: { ...this.state.weightage, value: '', error: '' },
                pfaByEmp: { ...this.state.pfaByEmp, value: '', error: '' },
                remarks: { ...this.state.remarks, value: '', error: '' },

            })
    }

    private onEdit = (i: Number, item: any, type: String,) => {
        
        this.setState({
            name: { ...this.state.name, value: [{ code: item.code, name: item.name }] },
            weightage: { ...this.state.weightage ,  value: item.weightage },
            pfaByEmp: { ...this.state.pfaByEmp , value: item.pfaByEmp },
            remarks: { ...this.state.remarks, value: item.remarks },
            check: { ...this.state.check, value: item.check },
            updateByName: item.name
        });
        this.toggleWindow(type, true)
    }

    private onUpdate = (e: any) => {
        e.preventDefault()
        const { type, activity, behaviour, updateByName, department, name, check, remarks, weightage, pfaByEmp }: any = this.state
        if (validateForm(this, { name, weightage })) {
            if (type == activity.name) {

                const index = activity.value.findIndex((item: any) => item.name === updateByName);
                activity.value[index] = { ...activity.value[index], name: name.value[0].name, code: activity.value[index].code, check: check.value, remarks: remarks.value, weightage: weightage.value, pfaByEmp: pfaByEmp.value }
                onChange(this, activity.name, activity.value);
            }
            else if (type == behaviour.name) {

                const index = behaviour.value.findIndex((item: any) => item.name === updateByName);
                behaviour.value[index] = { ...behaviour.value[index], name: name.value[0].name, code: behaviour.value[index].code, remarks: remarks.value, weightage: weightage.value, pfaByEmp: pfaByEmp.value }
                onChange(this, behaviour.name, behaviour.value);
            }
            else if (type == department.name) {
                const index = department.value.findIndex((item: any) => item.name === updateByName);
                department.value[index] = { ...department.value[index], name: name.value[0].name, code: department.value[index].code, remarks: remarks.value, weightage: weightage.value, pfaByEmp: pfaByEmp.value }
                onChange(this, department.name, department.value);
            }
            this.toggleWindow()
        }
    }
    private onDelete = (i: any, type: String) => {
        const { activity, behaviour, department }: any = this.state
        if (type == activity.name) {
            activity.value.splice(i, 1);
            onChange(this, activity.name, activity.value);
        }
        else if (type == behaviour.name) {
            behaviour.value.splice(i, 1);
            onChange(this, behaviour.name, behaviour.value);
        }
        else if (type == department.name) {
            department.value.splice(i, 1);
            onChange(this, department.name, department.value);
        }
    }

    private _typeaheadOnChange(name: string, e: any) {
        // debugger
        let value = e;
        if (e.length > 0 && e[0].customOption) {
            value = [{ name: e[0].name }];
        }
        onChange(this, name, value);
        if (this.state[name].error && this.state[name].error.length > 0)
            setError(this, name, '')
    }

    private _onChange = (e: any, callback?: any) => {

        const name = e.target.name;
        let value = e.target.value;

        if (name == this.state.check.name) {
            value = e.target.checked
        }
        if (/**name === this.state.yearCycle.name || */ name === this.state.monthCycle.name) {
            onChange(this, name, value, () => {
                const { designation, } = JSON.parse(window.atob(getCookie('token').split('.')[1]));
                this.getWeightage(designation, this.state.yearCycle.value, this.state.monthCycle.value)
            });
        }
        else if (name === this.state.weightage.name) {
            if (this.state.type == this.state.activity.name && Number(value) > Number(this.state.constrant ? this.state.constrant.maxActivities : 0)) {
                alert(`single activity should be less than ${this.state.constrant ? this.state.constrant.maxActivities : 'yet not set by admin'}`)
            }
            else if (this.state.type == this.state.behaviour.name && Number(value) > Number(this.state.constrant ? this.state.constrant.maxBehaviour : 0)) {
                alert(`single activity should be less than ${this.state.constrant ? this.state.constrant.maxBehaviour : 'yet not set by admin'}`)
            }
            else if (this.state.type == this.state.department.name && Number(value) > Number(this.state.constrant ? this.state.constrant.maxDepartment : 0)) {
                alert(`single activity should be less than  ${this.state.constrant ? this.state.constrant.maxDepartment : 'yet not set by admin'}`)
            }
            else
                onChange(this, name, value);

        }
        else
            onChange(this, name, value);

        if (name === this.state.yearCycle.name) {
            let mnth = value && this.state.yearCycle.options.filter((res: any) => { return res.code == value; }).map((res: any) => res.performance)
            mnth = mnth.length && mnth[0].sort((a: any, b: any,) => b.createdOn - a.createdOn);
            this.setState({
                monthCycle: { ...this.state.monthCycle, options: mnth },
            })
        }
        if (this.state[name].error && this.state[name].error.length > 0)
            setError(this, name, '');
    }

    renderSelect = (select: any, f: boolean) => {
        return (<select disabled={this.props.code ? true : false} name={select.name} value={select.value}
            onChange={this._onChange} className={select.error.length > 0 ? "form-control is-invalid" : "form-control"}>
            <React.Fragment>
                <option>--Select--</option>
                {select.options.length && select.options.map((e: any, dIndex: number) => {
                    return e && (<option key={dIndex} value={e.code}>{f ? e.name : e.yearCycle}</option>)
                })}
            </React.Fragment>
        </select>)
    }

    onAdd = (e: any) => {
        e.preventDefault();
        const { type, name, remarks, activity, behaviour, department, weightage, pfaByEmp } = this.state
        const wp = this.props.code ? (type == activity.name ? { name, remarks, pfaByEmp, weightage } : { name, remarks, pfaByEmp }) : { name, weightage }


        if (type == activity.name) {
            const check = activity.value.find((v: any) => (v.name === name.value[0]?.name))
            if (check) {
                alert(`Activities already selected`)
                return
            }
        }
        else if (type == behaviour.name) {
            const check = behaviour.value.find((v: any) => (v.name === name.value[0].name))
            if (check) {
                alert(`Behavioural already selected`)
                return
            }
        }
        else if (type == department.name) {
            const check = department.value.find((v: any) => (v.name === name.value[0].name))
            if (check) {
                alert(`Directorate Specific Skills already selected`)
                return
            }
        }


        if (validateForm(this, wp)) {
            this.toggleWindow();
            const model = { code: uniqId(), name: name.value[0].name, check: true, remarks: remarks.value, weightage: weightage.value, pfaByEmp: pfaByEmp.value, pfaByMn: [], pfaByRm: [], new: true }
            if (type == activity.name) {
                const check = activity.value.find((v: any) => (v.name === name.value[0].name))
                if (check) {
                    alert(`already selected`)
                    return
                }
                onArrayChange(this, activity.name, model);
            }
            else if (type == behaviour.name) {
                const check = behaviour.value.find((v: any) => (v.name === name.value[0].name))
                if (check) {
                    alert(`already selected`)
                    return
                }
                onArrayChange(this, behaviour.name, model);
            }
            else if (type == department.name) {
                const check = department.value.find((v: any) => (v.name === name.value[0].name))
                if (check) {
                    alert(`already selected`)
                    return
                }
                onArrayChange(this, department.name, model);
            }

            this.setState({
                weightage: { ...this.state.weightage, value: '' },
                pfaByEmp: { ...this.state.pfaByEmp, value: '' },
                remarks: { ...this.state.remarks, value: '' },
                name: { ...this.state.name, value: '' },

            })
        }

    }

    private loadList = (designation: string, department: string) => {
        
        getAppraisalCycle().then((res: any) => {
            if (res && res.result && res.result.length)
                this.setState({
                    yearCycle: { ...this.state.yearCycle, options: res.result, value: res.result[0].code },
                    monthCycle: { ...this.state.monthCycle, options: res.result[0].performance },
                  });
                });

           
                  
        gradeList().then((res: any) => {
            if (res && res.result && res.result.length)
                this.setState({ pfaByEmp: { ...this.state.pfaByEmp, options: res.result } })
        });


        this.props.code && getGoal(this.props.code).then((res: any) => {
             
            const { behaviour, department, activity, approved, comments, yearCycle, monthCycle } = this.state
           
             if (res && res.result && !isEmpty(res.result)) {
                 
                onArrayChange(this, department.name, res.result.department);
                onArrayChange(this, behaviour.name, res.result.behaviour);
                onArrayChange(this, activity.name, res.result.activity)
                onChange(this, comments.name, res.result.comments)
                onChange(this, yearCycle.name, res.result.yearCycle)
                onChange(this, monthCycle.name, res.result.monthCycle)
                onChange(this, approved.name, res.result.approved)
                const check = [...res.result.behaviour, ...res.result.department, ...res.result.activity].every((e) => e.pfaByEmp.length > 0)
                this.setState({ self: res.result.selfAppraisal, sub: check })
                const { designation, } = JSON.parse(window.atob(getCookie('token').split('.')[1]));
                this.getWeightage(designation, res.result.yearCycle, res.result.monthCycle)
            }
             
        });




        getCompetenciesList(designation).then((res: any) => {
            if (res && res.result && !isEmpty(res.result))
                this.setState({
                    name: { ...this.state.name , boptions: res.result.competencies },
                   });
            });
         
         
 
    
        getSkillsList(department).then((res: any) => {
            
             if (res && res.result && !isEmpty(res.result))
                this.setState({
                    name: { ...this.state.name, doptions: res.result.skills },
                });
        });

         
    }

    getWeightage = (designation: string, yearCycle: string, monthCycle: string) => {
        getWeightage(designation, yearCycle, monthCycle).then((res: any) => {
            if (res && res.result)
                this.setState({
                    constrant: res.result
                });
        });
    }
    private onSubmit = (e: any) => {
       
        const { yearCycle, monthCycle, sub, updated, behaviour, department, activity, comments, self } = this.state
        e.preventDefault();
        const model = {
            yearCycle: yearCycle.value,
            monthCycle: monthCycle.value,
            behaviour: behaviour.value,
            department: department.value,
            activity: activity.value,
            comments: comments.value,
            code: this.props.code,
            updated: updated.value,
            designation: JSON.parse(window.atob(getCookie('token').split('.')[1])).designation

        };
        const a = model.activity.filter((ac: any) => ac.check == true);
        let totalcount = 0;
        for (let ac of a) {
            totalcount += Number(ac.weightage);
        }
        // const a1 = model.behaviour.filter((ac: any) => ac.check == true);
        let totalcount1 = 0;
        for (let be of model.behaviour) {
            totalcount1 += Number(be.weightage);
        }
        // const a2 = model.department.filter((ac: any) => ac.check == true);
        let totalcount2 = 0;
        for (let de of model.department) {
            totalcount2 += Number(de.weightage);
        }

        if (validateForm(this, { yearCycle, behaviour, department, activity })) {
            if (Number(totalcount) !== 100) {
                alert(`Total Activity Weightage Should be Equal  100 ===>${Number(totalcount)}`)
                return
            }
            else if (Number(totalcount1) !== 100) {
                alert(`Total Behaviour Weightage Should be Equal  100`)
                return
            }
            else if (Number(totalcount2) !== 100) {
                alert(`Total Activity Department Should be Equal  100`)
                return
            }
            else {
                if (this.props.code) {
                    let r = window.confirm("I have confirm my Assessment");
                    if (r) {
                        if (self && ![...behaviour.value, ...department.value, ...activity.value].every((e) => e.pfaByEmp.length > 0)) {
                            alert("Please complete all activities.")
                            return
                        }
                        if (self && sub) {
                            alert("You do not have permission to resubmit.")
                            return
                        }
                        else
                            this.props.onSubmit(model)
                    }
                } else
                    this.props.onSubmit(model)
            }
        }
    }
}

export default Add;
