import React from 'react';
import ReactDOM from 'react-dom';
import { savePDF } from '@progress/kendo-react-pdf';
import pdfLogo from '../../assets/images/logo_pdf.png';
import GoalHistory from '../../container/employee/setGoal/GoalHistory';
import { onChange } from '../../utils';
import moment from 'moment';

interface AppState {
    data: any[];
    filter: { name: string; value: string; error: string; isRequired: boolean };
}

export default class App extends React.Component<any, AppState> {
    table: HTMLElement | null = null;

    constructor(props: any) {
        super(props);
        this.state = {
            data: props.data,
            filter: { name: 'filter', value: 'all', error: '', isRequired: true },
        };
    }

    // Helper function to match code and userCode
    getMatchingData = (pfaArray: any[], userCode: string) => {
        return pfaArray.find((item) => item.userCode === userCode) || {};
    };

    // hasRequiredData = (l: any) => {
    //     const reportingOfficerCode = l.reportingOfficer?.[0]?.code || '';
    //     const secondReportingOfficerCode = l.secondReportingOfficer?.[0]?.code || '';
    //     const reviewingOfficerCode = l.reviewingOfficer?.[0]?.code || '';
    //     const secondReviewingOfficerCode = l.secondReviewingOfficer?.[0]?.code || '';
    
    //     // Check if reporting officer has at least one percentage in any table
    //     const hasReportingOfficerData =
    //         l.goal?.behaviour?.some((e: any) => {
    //             const reportingOfficerData = this.getMatchingData(e.pfaByMn || [], reportingOfficerCode);
    //             return reportingOfficerData.percentage !== undefined;
    //         }) ||
    //         l.goal?.department?.some((e: any) => {
    //             const reportingOfficerData = this.getMatchingData(e.pfaByMn || [], reportingOfficerCode);
    //             return reportingOfficerData.percentage !== undefined;
    //         }) ||
    //         l.goal?.activity?.some((e: any) => {
    //             const reportingOfficerData = this.getMatchingData(e.pfaByMn || [], reportingOfficerCode);
    //             return reportingOfficerData.percentage !== undefined;
    //         });
    
    //     const hasSecondReportingOfficerData =
    //         !l.secondReportingOfficer?.length || // Skip if second reporting officer is not present
    //         l.goal?.behaviour?.some((e: any) => {
    //             const secondReportingOfficerData = this.getMatchingData(e.pfaByMn || [], secondReportingOfficerCode);
    //             return secondReportingOfficerData.percentage !== undefined;
    //         }) ||
    //         l.goal?.department?.some((e: any) => {
    //             const secondReportingOfficerData = this.getMatchingData(e.pfaByMn || [], secondReportingOfficerCode);
    //             return secondReportingOfficerData.percentage !== undefined;
    //         }) ||
    //         l.goal?.activity?.some((e: any) => {
    //             const secondReportingOfficerData = this.getMatchingData(e.pfaByMn || [], secondReportingOfficerCode);
    //             return secondReportingOfficerData.percentage !== undefined;
    //         });
    
    //     // Check if reviewing officer has at least one percentage in any table
    //     const hasReviewingOfficerData =
    //         l.goal?.behaviour?.some((e: any) => {
    //             const reviewingOfficerData = this.getMatchingData(e.pfaByRm || [], reviewingOfficerCode);
    //             return reviewingOfficerData.percentage !== undefined;
    //         }) ||
    //         l.goal?.department?.some((e: any) => {
    //             const reviewingOfficerData = this.getMatchingData(e.pfaByRm || [], reviewingOfficerCode);
    //             return reviewingOfficerData.percentage !== undefined;
    //         }) ||
    //         l.goal?.activity?.some((e: any) => {
    //             const reviewingOfficerData = this.getMatchingData(e.pfaByRm || [], reviewingOfficerCode);
    //             return reviewingOfficerData.percentage !== undefined;
    //         });
    
    //     // Check if second reviewing officer has at least one percentage in any table (if present)
    //     const hasSecondReviewingOfficerData =
    //         !l.secondReviewingOfficer?.length || // Skip if second reviewing officer is not present
    //         l.goal?.behaviour?.some((e: any) => {
    //             const secondReviewingOfficerData = this.getMatchingData(e.pfaByRm || [], secondReviewingOfficerCode);
    //             return secondReviewingOfficerData.percentage !== undefined;
    //         }) ||
    //         l.goal?.department?.some((e: any) => {
    //             const secondReviewingOfficerData = this.getMatchingData(e.pfaByRm || [], secondReviewingOfficerCode);
    //             return secondReviewingOfficerData.percentage !== undefined;
    //         }) ||
    //         l.goal?.activity?.some((e: any) => {
    //             const secondReviewingOfficerData = this.getMatchingData(e.pfaByRm || [], secondReviewingOfficerCode);
    //             return secondReviewingOfficerData.percentage !== undefined;
    //         });
    
    //     // Return true only if all present officers have at least one percentage in any table

    //     return (
    //         hasReportingOfficerData &&
    //         hasSecondReportingOfficerData &&
    //         hasReviewingOfficerData &&
    //         hasSecondReviewingOfficerData
    //     );
    // };

    
    render() {
        const { data, filter } = this.state;

        return (
            <section>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mt-2">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-6 col-lg-6 form-group">
                                        <label>Goal Tracker</label>
                                        <select
                                            name={filter.name}
                                            value={filter.value}
                                            onChange={this._onChange}
                                            className={filter.error.length > 0 ? "form-control is-invalid" : "form-control"}
                                        >
                                            <option value="all">All Employee</option>
                                            <option value="pending">Pending Employee</option>
                                            <option value="approved">Approved Employee</option>
                                            <option value="reject">Rejected Employee</option>
                                            <option value="completed">Completed Employee</option>
                                            <option value="dualReporting">Dual Reporting Employee</option>
                                        </select>
                                    </div>
                                    <div className="col-6 col-lg-6 form-group">
                                        <button className="col-lg-4 btn button-full mt-4" onClick={this.exportPDF}>
                                            Export PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" id="printArea" style={{ marginTop: 60 }} ref={(table) => { this.table = table; }}>
                                {data.length > 0 &&
                                    data.map((l: any, i: number) => {
                                        const reportingOfficerCode = l.reportingOfficer?.[0]?.code || '';
                                        const reviewingOfficerCode = l.reviewingOfficer?.[0]?.code || '';
                                        const secondReportingOfficerCode = l.secondReportingOfficer?.[0]?.code || '';
                                        const secondReviewingOfficerCode = l.secondReviewingOfficer?.[0]?.code || '';

                                        return (
                                            <div key={i}>
                                                {/* PART-1: Personal Data */}
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={(l.secondReportingOfficer?.length > 0 && l.secondReviewingOfficer?.length > 0) ? 9 : 7} className="text-center">
                                                                <h3><b>{`PMS APPRAISAL REPORT OF ${l.subPerformanceCycle?.name} ${l.yearCycle?.yearCycle}`}</b></h3>
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={(l.secondReportingOfficer?.length > 0 && l.secondReviewingOfficer?.length > 0) ? 9 : 7} className="text-center">
                                                                Assessment Report
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={(l.secondReportingOfficer?.length > 0 && l.secondReviewingOfficer?.length > 0) ? 9 : 7} className="text-center">
                                                                PART-1 (Personal Data)
                                                            </th>
                                                        </tr>
                                                        <tr>
                                                            <th>Appraisal Cycle</th>
                                                            <th>Sub Appraisal Cycle</th>
                                                            <th>Name of the Employee</th>
                                                            <th>Department</th>
                                                            <th>Designation</th>
                                                            <th>Reporting Officer</th>
                                                            <th>Reviewing Officer</th>
                                                            {l.secondReportingOfficer?.length > 0 && <th>Second Reporting Officer</th>}
                                                            {l.secondReviewingOfficer?.length > 0 && <th>Second Reviewing Officer</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{l.yearCycle?.yearCycle}</td>
                                                            <td>{l.subPerformanceCycle?.name}</td>
                                                            <td>{l.name} ({l.empCode})</td>
                                                            <td>{l.department}</td>
                                                            <td>{l.designation}</td>
                                                            <td>{l.reportingOfficer?.[0]?.name || ''}</td>
                                                            <td>{l.reviewingOfficer?.[0]?.name || ''}</td>
                                                            {l.secondReportingOfficer?.length > 0 && <td>{l.secondReportingOfficer[0]?.name}</td>}
                                                            {l.secondReviewingOfficer?.length > 0 && <td>{l.secondReviewingOfficer[0]?.name}</td>}
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {/* PART-2: Self Assessment */}
                                                <br /><br />
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4} className="text-center">PART-2 (Self Assessment)</th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={4}>Behavioral Skills</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {l.goal?.behaviour?.length > 0 &&
                                                            l.goal.behaviour.map((e: any, bi: number) => {
                                                                return (
                                                                    <tr key={bi}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        <td>{e.pfaByEmp.length > 0 && e.pfaByEmp[0].percentage}</td>
                                                                        <td>{e.remarks}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                                {/* Departmental Skills - Part 2 */}
                                                <br /><br />
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4}>Departmental Skills</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {l.goal?.department?.length > 0 &&
                                                            l.goal.department.map((e: any, bi: number) => {
                                                                return (
                                                                    <tr key={bi}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        <td>{e.pfaByEmp.length > 0 && e.pfaByEmp[0].percentage}</td>
                                                                        <td>{e.remarks}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>

                                                {/* Assignment - Part 2 */}
                                                <br /><br />
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={5}>Assignment</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Worked</th>
                                                            <th>Marks</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {l.goal?.activity?.length > 0 &&
                                                            l.goal.activity.map((e: any, bi: number) => {
                                                                return (
                                                                    <tr key={bi}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        <td>{e.check ? 'YES' : 'NO'}</td>
                                                                        <td>{e.pfaByEmp.length > 0 && e.pfaByEmp[0].percentage}</td>
                                                                        <td>{e.remarks}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>


                                                {/* PART-3: Assessment by Reporting Officer */}
                                                <br /><br />
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4} className="text-center">PART-3 (Assessment by Reporting Officer)</th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={4}>Behavioral Skills</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {l.goal?.behaviour?.length > 0 &&
                                                            l.goal.behaviour.map((e: any, bi: number) => {
                                                                const reportingOfficerData = this.getMatchingData(e.pfaByMn || [], reportingOfficerCode);
                                                                return (
                                                                    <tr key={bi}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        <td>{reportingOfficerData.percentage || ''}</td>
                                                                        <td>{reportingOfficerData.remarks || ''}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                                 {/* Departmental Skills - Part 3 */}
                                                 <br /><br />
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4}>Departmental Skills</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {l.goal?.department?.length > 0 &&
                                                            l.goal.department.map((e: any, bi: number) => {
                                                                const reportingOfficerData = this.getMatchingData(e.pfaByMn || [], reportingOfficerCode);
                                                                return (
                                                                    <tr key={bi}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        <td>{reportingOfficerData.percentage || ''}</td>
                                                                        <td>{reportingOfficerData.remarks || ''}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>

                                                {/* Assignment - Part 3 */}
                                                <br /><br />
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={5}>Assignment</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Worked</th>
                                                            <th>Marks</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {l.goal?.activity?.length > 0 &&
                                                            l.goal.activity.map((e: any, bi: number) => {
                                                                const reportingOfficerData = this.getMatchingData(e.pfaByMn || [], reportingOfficerCode);
                                                                return (
                                                                    <tr key={bi}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        <td>{e.check ? 'YES' : 'NO'}</td>
                                                                        <td>{reportingOfficerData.percentage || ''}</td>
                                                                        <td>{reportingOfficerData.remarks || ''}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>


                                                {/* PART-4: Assessment by Second Reporting Officer */}
                                                {l.secondReportingOfficer?.length > 0 && (
                                                    <>
                                                        <br /><br />
                                                        <table className="table table-bordered tab">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={4} className="text-center">PART-4 (Assessment by Second Reporting Officer)</th>
                                                                </tr>
                                                                <tr>
                                                                    <th colSpan={4}>Behavioral Skills</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Weightage</th>
                                                                    <th>Marks</th>
                                                                    <th>Comments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {l.goal?.behaviour?.length > 0 &&
                                                                    l.goal.behaviour.map((e: any, bi: number) => {
                                                                        const secondReportingOfficerData = this.getMatchingData(e.pfaByMn || [], secondReportingOfficerCode);
                                                                        return (
                                                                            <tr key={bi}>
                                                                                <td>{e.name}</td>
                                                                                <td>{e.weightage}</td>
                                                                                <td>{secondReportingOfficerData.percentage || ''}</td>
                                                                                <td>{secondReportingOfficerData.remarks || ''}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                        
                                                      {/* Departmental Skills - Part 4 */}
                                                      <br /><br />
                                                        <table className="table table-bordered tab">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={4}>Departmental Skills</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Weightage</th>
                                                                    <th>Marks</th>
                                                                    <th>Comments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {l.goal?.department?.length > 0 &&
                                                                    l.goal.department.map((e: any, bi: number) => {
                                                                        const secondReportingOfficerData = this.getMatchingData(e.pfaByMn || [], secondReportingOfficerCode);
                                                                        return (
                                                                            <tr key={bi}>
                                                                                <td>{e.name}</td>
                                                                                <td>{e.weightage}</td>
                                                                                <td>{secondReportingOfficerData.percentage || ''}</td>
                                                                                <td>{secondReportingOfficerData.remarks || ''}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </tbody>
                                                        </table>

                                                        {/* Assignment - Part 4 */}
                                                        <br /><br />
                                                        <table className="table table-bordered tab">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={5}>Assignment</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Weightage</th>
                                                                    <th>Worked</th>
                                                                    <th>Marks</th>
                                                                    <th>Comments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {l.goal?.activity?.length > 0 &&
                                                                    l.goal.activity.map((e: any, bi: number) => {
                                                                        const secondReportingOfficerData = this.getMatchingData(e.pfaByMn || [], secondReportingOfficerCode);
                                                                        return (
                                                                            <tr key={bi}>
                                                                                <td>{e.name}</td>
                                                                                <td>{e.weightage}</td>
                                                                                <td>{e.check ? 'YES' : 'NO'}</td>
                                                                                <td>{secondReportingOfficerData.percentage || ''}</td>
                                                                                <td>{secondReportingOfficerData.remarks || ''}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )}


                                                {/* PART-5: Assessment by Reviewing Officer */}
                                                <br /><br />
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4} className="text-center">PART-5 (Assessment by Reviewing Officer)</th>
                                                        </tr>
                                                        <tr>
                                                            <th colSpan={4}>Behavioral Skills</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {l.goal?.behaviour?.length > 0 &&
                                                            l.goal.behaviour.map((e: any, bi: number) => {
                                                                const reviewingOfficerData = this.getMatchingData(e.pfaByRm || [], reviewingOfficerCode);
                                                                return (
                                                                    <tr key={bi}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        <td>{reviewingOfficerData.percentage || ''}</td>
                                                                        <td>{reviewingOfficerData.remarks || ''}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
  {/* Departmental Skills - Part 5 */}
  <br /><br />
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4}>Departmental Skills</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {l.goal?.department?.length > 0 &&
                                                            l.goal.department.map((e: any, bi: number) => {
                                                                const reviewingOfficerData = this.getMatchingData(e.pfaByRm || [], reviewingOfficerCode);
                                                                return (
                                                                    <tr key={bi}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        <td>{reviewingOfficerData.percentage || ''}</td>
                                                                        <td>{reviewingOfficerData.remarks || ''}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>

                                                {/* Assignment - Part 5 */}
                                                <br /><br />
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={5}>Assignment</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Weightage</th>
                                                            <th>Worked</th>
                                                            <th>Marks</th>
                                                            <th>Comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {l.goal?.activity?.length > 0 &&
                                                            l.goal.activity.map((e: any, bi: number) => {
                                                                const reviewingOfficerData = this.getMatchingData(e.pfaByRm || [], reviewingOfficerCode);
                                                                return (
                                                                    <tr key={bi}>
                                                                        <td>{e.name}</td>
                                                                        <td>{e.weightage}</td>
                                                                        <td>{e.check ? 'YES' : 'NO'}</td>
                                                                        <td>{reviewingOfficerData.percentage || ''}</td>
                                                                        <td>{reviewingOfficerData.remarks || ''}</td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>

                                                {/* PART-6: Assessment by Second Reviewing Officer */}
                                                {l.secondReviewingOfficer?.length > 0 && (
                                                    <>
                                                        <br /><br />
                                                        <table className="table table-bordered tab">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={4} className="text-center">PART-6 (Assessment by Second Reviewing Officer)</th>
                                                                </tr>
                                                                <tr>
                                                                    <th colSpan={4}>Behavioral Skills</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Weightage</th>
                                                                    <th>Marks</th>
                                                                    <th>Comments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {l.goal?.behaviour?.length > 0 &&
                                                                    l.goal.behaviour.map((e: any, bi: number) => {
                                                                        const secondReviewingOfficerData = this.getMatchingData(e.pfaByRm || [], secondReviewingOfficerCode);
                                                                        return (
                                                                            <tr key={bi}>
                                                                                <td>{e.name}</td>
                                                                                <td>{e.weightage}</td>
                                                                                <td>{secondReviewingOfficerData.percentage || ''}</td>
                                                                                <td>{secondReviewingOfficerData.remarks || ''}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    {/* Departmental Skills - Part 6 */}
                                                    <br /><br />
                                                        <table className="table table-bordered tab">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={4}>Departmental Skills</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Weightage</th>
                                                                    <th>Marks</th>
                                                                    <th>Comments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {l.goal?.department?.length > 0 &&
                                                                    l.goal.department.map((e: any, bi: number) => {
                                                                        const secondReviewingOfficerData = this.getMatchingData(e.pfaByRm || [], secondReviewingOfficerCode);
                                                                        return (
                                                                            <tr key={bi}>
                                                                                <td>{e.name}</td>
                                                                                <td>{e.weightage}</td>
                                                                                <td>{secondReviewingOfficerData.percentage || ''}</td>
                                                                                <td>{secondReviewingOfficerData.remarks || ''}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </tbody>
                                                        </table>

                                                        {/* Assignment - Part 6 */}
                                                        <br /><br />
                                                        <table className="table table-bordered tab">
                                                            <thead>
                                                                <tr>
                                                                    <th colSpan={5}>Assignment</th>
                                                                </tr>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Weightage</th>
                                                                    <th>Worked</th>
                                                                    <th>Marks</th>
                                                                    <th>Comments</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {l.goal?.activity?.length > 0 &&
                                                                    l.goal.activity.map((e: any, bi: number) => {
                                                                        const secondReviewingOfficerData = this.getMatchingData(e.pfaByRm || [], secondReviewingOfficerCode);
                                                                        return (
                                                                            <tr key={bi}>
                                                                                <td>{e.name}</td>
                                                                                <td>{e.weightage}</td>
                                                                                <td>{e.check ? 'YES' : 'NO'}</td>
                                                                                <td>{secondReviewingOfficerData.percentage || ''}</td>
                                                                                <td>{secondReviewingOfficerData.remarks || ''}</td>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )}

                                                {/* PART-7: Overall Assessment Report */}
                                                <br /><br />
                                                <table className="table table-bordered tab">
                                                    <thead>
                                                        <tr>
                                                            <th colSpan={4} className="text-center">Part-7 (Overall Assessment Report)</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Particular</th>
                                                            <th>Weightage</th>
                                                            <th>Marks</th>
                                                            <th>Rating</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Behavioral</td>
                                                            <td>{l.goal?.behaviourweightage}</td>
                                                            <td>{l.goal?.behaviourMarks}</td>
                                                            <td>{l.goal?.behaviourGrade}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Departmental</td>
                                                            <td>{l.goal?.departmentweightage}</td>
                                                            <td>{l.goal?.departmentMarks}</td>
                                                            <td>{l.goal?.departmentGrade}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>KRA</td>
                                                            <td>{l.goal?.activityweightage}</td>
                                                            <td>{l.goal?.activityMarks}</td>
                                                            <td>{l.goal?.activityGrade}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Overall</td>
                                                            <td>{l.goal?.totalWeightage}</td>
                                                            <td>{parseInt(l.goal?.totalMarks)}</td>
                                                            <td>{l.goal?.totalGrad}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <h4 className="text-center">Page {`${i + 1} / ${data.length}`}</h4>
                                                <br className="page-break" />
                                                {!(i + 1 === data.length) && <br className="page-break" />}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    private _onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const name = e.target.name;
        const value = e.target.value;
        onChange(this, name, value);
        this.filter(value);
    };

    private filter = (f: string) => {
        const res = this.props.data.filter((item: any) => {
            if (f === 'completed') return item.goal.selfAppraisal === true;
            else if (f === 'pending') return item.goal.approved === 'pending';
            else if (f === 'reject') return item.goal.approved === 'reject';
            else if (f === 'approved') return item.goal.approved === 'approved';
            else if (f === 'dualReporting') return item.secondReportingOfficer.length > 0 && item.secondReviewingOfficer.length > 0;
            else return true;
        });
        this.setState({ data: res });
    };

    exportPDF = () => {
        const element: HTMLElement | null = ReactDOM.findDOMNode(this.table) as HTMLElement;
        if (element) {
            savePDF(element, {
                repeatHeaders: false,
                paperSize: "A4",
                margin: '0cm',
                forcePageBreak: ".page-break",
                date: new Date(),
                fileName: this.props.data.length > 0
                    ? `${this.props.data[0].subPerformanceCycle.name.replace(/\s+/g, '-').toLowerCase()}-${this.props.data[0].yearCycle.yearCycle.replace(/\s+/g, '-').toLowerCase()}`
                    : `${moment().format('YYYY-MM-DD-hh:mm')}`,
                scale: 0.6,
            });
        }
    };
}